import React from 'react'
import { jsonLdScriptProps } from 'react-schemaorg'
import Head from 'next/head'

interface SGLinkedDataProps {
  // TODO typechecking of schema-dts seems broken in the current
  // version of Typescript - lots of massive errors about same
  // looking types not matching, typechecker overflowing the stack
  // in the editor
  // item?: WithContext<Thing> | null
  item?: any
}

/** @description used to make sure the schema is in the head */
export const SGLinkedData: React.FC<
  React.PropsWithChildren<SGLinkedDataProps>
> = ({ item }) =>
  item ? (
    <Head>
      <script {...jsonLdScriptProps(item)} />
    </Head>
  ) : (
    <></>
  )
